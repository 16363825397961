<template>
  <div class="md:mt-8">
    <h4 class="font-medium text-xl md:text-3xl text-dark-blue mb-4 md:mb-6">{{ currentLegal.documentName }}</h4>
    <vx-card class="terms-card custom-quillEditor">
      <div v-if="currentLegal.explicitTerms" v-html="currentLegal.explicitTerms" class="text-base terms-view-content explicit"></div>
      <vs-divider class="terms-divider" v-if="currentLegal.explicitTerms"></vs-divider>
      <div v-html="currentLegal.fullDocument" class="text-base terms-view-content" id="fullTerms"></div>
    </vx-card>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import QuillEditorPreview from "@/views/QuillEditorPreview";

export default {
  name: "TermsView",
  components: {
    QuillEditorPreview,
  },
  data() {
    return {
      currentLegal: "",
      type: "",
      fullDocument: true,
      appUrl: process.env.VUE_APP_BASE_URL,
    };
  },
  methods: {
    ...mapActions("legals", ["fetchAllLegals"]),
    getTerms(id, type) {
      this.$vs.loading();
      this.fetchAllLegals(type).then((result) => {
        this.$vs.loading.close();

        const legals = result.data.data.legals;
        const latestPrivacy = result.data.data.privacy;
        const latestWebsiteTerms = result.data.data.websiteTerms;
        this.currentLegal = legals.find((el) => el._id === id);

        if (this.fullDocument) {
          this.currentLegal.fullDocument = this.replaceLegalContent(
            this.currentLegal.fullDocument,
            {
              fullTerms: `<a href="#fullTerms">${this.currentLegal.documentName}</a>`,
              privacy: `<a href="${latestPrivacy._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestPrivacy.documentName}</a>`,
              generalWebsiteTerms: `<a href="${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestWebsiteTerms.documentName}</a>`,
            }
          );
        }

        if (this.currentLegal.explicitTerms) {
          this.currentLegal.explicitTerms = this.replaceLegalContent(
            this.currentLegal.explicitTerms,
            {
              fullTerms: `<a href="#fullTerms">${this.currentLegal.documentName}</a>`,
                privacy: `<a href="${latestPrivacy._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestPrivacy.documentName}</a>`,
              generalWebsiteTerms: `<a href="${latestWebsiteTerms._id}?type=DASHBOARD&fullDocument=true" target="_blank">${latestWebsiteTerms.documentName}</a>`,
            }
          );
        }
      }).catch((ex) => {
        this.$vs.loading.close();

        this.$vs.notify({
          title: "Error",
          text: "Error fetching legals",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "danger",
        });
      });
    },
  },

  async beforeMount() {
    this.type = this.$route.query.type;
    const id = this.$route.params.id;
    await this.getTerms(id, this.type);
  },
};
</script>